


























import { defineComponent, ref, watch } from '@vue/composition-api';
import { useRouter } from '@/router';

export default defineComponent({
    setup() {
        const router = useRouter();
        const preventNextTransition = ref(false);

        watch(() => router.currentRoute, (to) => {
            preventNextTransition.value = to.name === 'network';
        }, { lazy: true });

        return {
            preventNextTransition,
        };
    },
});
