


































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { Tooltip } from '@nimiq/vue-components';
import { useRouter, RouteName } from '@/router';
import { useAddressStore } from '../../stores/Address';
import { useStakingStore } from '../../stores/Staking';
import { useConfig } from '../../composables/useConfig';
import { useWindowSize } from '../../composables/useWindowSize';
import { MIN_STAKE } from '../../lib/Constants';
import HeroIcon from '../icons/Staking/HeroIcon.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const { config } = useConfig();
        const { activeAddressInfo } = useAddressStore();
        const { totalAccountStake, totalActiveStake } = useStakingStore();
        const { isMobile } = useWindowSize();

        const $CTATooltip = ref<Tooltip | null>(null);
        const $tooltip = ref<Tooltip | null>(null);

        /**
         * The user can stake if they have a balance of at least MIN_STAKE.
         */
        const canStake = computed(() => !!(
            !config.disableNetworkInteraction
            && activeAddressInfo.value
            && activeAddressInfo.value.balance
            && activeAddressInfo.value.balance >= MIN_STAKE
        ));

        /**
         * This function is implemented to prevent any user interaction on the button from closing the tooltip.
         * Therefore, the click event is handled on the parent element instead of the tooltip trigger itself.
         */
        function customClickHandler(e: Event) {
            if (canStake.value) {
                e.stopPropagation();
                e.preventDefault();
                router.push({ name: RouteName.Staking });
            }
        }

        /**
         * This function is used to update the tooltip visibility based on the following conditions:
         * - If the tooltip is not shown, the user is not staking and can stake => show the tooltip.
         * - If the tooltip is shown, the user is staking or cannot stake => hide the tooltip.
         */
        function updateTooltipVisibility() {
            if (!$CTATooltip.value) return;

            if (!totalActiveStake.value && canStake.value && !$CTATooltip.value.isShown) {
                $CTATooltip.value.show();
            } else if ($CTATooltip.value.isShown && (totalActiveStake.value || !canStake.value)) {
                $CTATooltip.value.hide();
            }
        }

        watch([$CTATooltip, activeAddressInfo, totalActiveStake], async () => {
            if (!isMobile.value) {
                updateTooltipVisibility();
            } else if (isMobile.value && $CTATooltip.value?.isShown) {
                $CTATooltip.value.hide();
            }
        });

        const isCTATooltipDisabled = computed(() =>
            !!(totalAccountStake.value || isMobile.value || !canStake.value),
        );

        /**
         * TODO:
         * - Add a "normal behaving" warning tooltip for when the user doesn't have enough funds to stake
         *   ("At least MIN_STAKE is required in order to stake" nq-orange)
         */

        return {
            // Store / Composable
            activeAddressInfo,
            totalAccountStake,
            totalActiveStake,
            isMobile,

            // DOM References / Vue Components
            $CTATooltip,
            $tooltip,

            // Functions / ref & computed
            canStake,
            customClickHandler,
            isCTATooltipDisabled,
            RouteName,
        };
    },
    components: {
        Tooltip,
        HeroIcon,
    },
});
