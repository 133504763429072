



























import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, watch } from '@vue/composition-api';
import { SmallPage, CloseButton } from '@nimiq/vue-components';
import { useRouter } from '@/router';
import { nextTick } from '@/lib/nextTick';
import { useWindowSize } from '../../composables/useWindowSize';
import { useSwipes } from '../../composables/useSwipes';
import { useSettingsStore } from '../../stores/Settings';
import { pointerdown } from '../../directives/PointerEvents';

export function enableModalTransition() {
    document.body.classList.remove('modal-transition-disabled');
}

export function disableNextModalTransition() {
    document.body.classList.add('modal-transition-disabled');
    // the modal transitions are enabled again in onMounted in the next modal component instance
}

const Modal = defineComponent({
    props: {
        emitClose: {
            type: Boolean,
            default: false,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        closeButtonInverse: {
            type: Boolean,
            default: false,
        },
        swipeToClose: {
            type: Boolean,
            default: true,
        },
        swipePadding: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, context) {
        const router = useRouter();

        function close() {
            if (props.showOverlay) {
                context.emit('close-overlay');
                return;
            }

            if (props.emitClose) {
                context.emit('close');
            } else {
                forceClose();
            }
        }

        async function forceClose() {
            // Ensures that we close all the modals that we navigated through, so flows that opens multiple modals in
            // different steps are closed one after the other.
            // For example: choose a sender via AddressSelectorModal -> open qr scanner from SendModal -> after scan in
            // ScanQrModal return to SendModal -> abort the action in SendModal

            while (router.currentRoute.matched.find((routeRecord) => 'modal' in routeRecord.components
                || 'persistent-modal' in routeRecord.components
                || Object.values(routeRecord.components).some((component) => /modal/i.test(
                    'name' in component ? component.name as string : '')))
            ) {
                router.back();

                // eslint-disable-next-line no-await-in-loop
                await new Promise((resolve) => {
                    window.addEventListener('popstate', resolve, { once: true });
                });
            }
        }

        const onEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                close();
            }
        };

        document.addEventListener('keydown', onEscape);

        onUnmounted(() => {
            document.removeEventListener('keydown', onEscape);
        });

        // Swiping
        const main$ = ref<HTMLDivElement>(null);
        const handle$ = ref<HTMLDivElement>(null);
        const showSwipeHandle = ref(false);

        async function updateSwipeRestPosition(
            velocityDistance: number,
            velocityTime: number,
            initialYPosition: number,
            currentYPosition: number,
        ) {
            if (velocityDistance && velocityTime) {
                const swipeFactor = 10;
                const velocity = (velocityDistance / velocityTime) * 1000 * swipeFactor; // px/s
                const remainingYDistance = Math.sqrt(Math.abs(velocity)) * (velocity / Math.abs(velocity));
                currentYPosition += remainingYDistance;
            }

            const closeBarrier = 160; // 20rem = 160px

            if (currentYPosition >= closeBarrier && initialYPosition < closeBarrier) {
                // Close modal
                close();
                await nextTick();
            }
        }

        if (props.swipeToClose) {
            const { isMobile, height } = useWindowSize();
            const { swipingEnabled } = useSettingsStore();

            const { attachSwipe, detachSwipe } = useSwipes(main$ as Ref<HTMLDivElement>, {
                onSwipeEnded: updateSwipeRestPosition,
                clampMovement: computed<[number, number]>(() => [0, height.value]),
                vertical: true,
                handle: handle$ as Ref<HTMLDivElement>,
            });

            watch(isMobile, (isMobileNow, wasMobile) => {
                if (!main$.value) return;

                if (isMobileNow && !wasMobile && swipingEnabled.value === 1) {
                    showSwipeHandle.value = true;
                    nextTick(attachSwipe);
                } else if (!isMobileNow && showSwipeHandle.value) {
                    detachSwipe();
                    showSwipeHandle.value = false;
                }
            }, { lazy: true });

            if (isMobile.value && swipingEnabled.value === 1) {
                showSwipeHandle.value = true;
                onMounted(attachSwipe);
            }
        }

        // Backdrop click handling
        let touchStartedOnBackdrop = false;

        function checkTouchStart(e: Event) {
            touchStartedOnBackdrop = !!e.target && (e.target as HTMLDivElement).matches('.backdrop, .wrapper');
        }

        function onBackdropClick() {
            if (!touchStartedOnBackdrop) return;
            close();
        }

        onMounted(() => {
            setTimeout(() => {
                enableModalTransition();
            }, 100);
        });

        return {
            forceClose, // exposed for use from other components

            close,
            main$,
            handle$,
            showSwipeHandle,
            checkTouchStart,
            onBackdropClick,
        };
    },
    directives: {
        pointerdown,
    },
    components: {
        SmallPage,
        CloseButton,
    },
});
// Export the component's instance type alongside the value (the constructor) via Typescript declaration merging,
// similar to what would be the case for a class-based component declaration, for convenient usage in Ref types.
type Modal = InstanceType<typeof Modal>;
export default Modal;
